<template>
  <VueSkeletonLoader
    v-if="loading"
    animation="wave"
    class="max-w-200"
    :color="'#bec3d8'"
    :height="20"
    type="rect"
    :width="20"
  />
  <div
    v-else-if="flagsDataList && flagsDataList.length"
    class="flex flex-wrap gap-9px"
    :class="{ 'pointer flex-nowrap': aggregate }"
    @click="clickOnChips"
  >
    <GlPriorityChip
      v-for="(item, i) in flagsDataList"
      :key="i"
      :class="{ 'count-wrap__empty': item.count === 0 }"
      :label="aggregate ? '' : item.title"
      :priority-level="item.priority"
      rounded="l"
      :score="item.score"
      :tooltip="aggregate ? '' : item.description"
    >
      <template
        v-if="!hideScore && item.score"
        #first
      >
        <div class="tag__score flags__score">
          {{ item.score }}
        </div>
      </template>
      <template #second>
        <div
          class="flex align-center gap-1"
          :class="{'flex-row-reverse': aggregate}"
        >
          <div
            v-if="item.count"
            class="count-wrap"
          >
            {{ item.count }}
          </div>
          <gl-icon
            :height="14"
            name="flag"
            style="min-width: 14px"
            :width="14"
          />
        </div>
      </template>
    </GlPriorityChip>
    <div v-if="flagsDataList.length < sortedFlagsData.length || aggregate">
      <div
        v-if="!aggregate"
        class="show-more-flags"
        @click="isModalOpen = true"
      >
        ●●●
      </div>
      <gl-modal
        capitalize-title
        closable
        full-buttons
        submit-title="OK"
        title="All Flags"
        :value="isModalOpen"
        width="500"
        v-on="$listeners"
        @close="isModalOpen = false"
        @submit="isModalOpen = false"
      >
        <div class="flex flex-wrap gap-9px mb-4">
          <GlPriorityChip
            v-for="(item, i) in sortedFlagsData"
            :key="i"
            :label="item.title"
            :priority-level="item.priority"
            rounded="l"
            :score="item.score"
            :tooltip="item.description"
          >
            <template
              v-if="!hideScore && item.score"
              #first
            >
              <div class="tag__score flags__score">
                {{ item.score }}
              </div>
            </template>
            <template #second>
              <div
                v-if="item.count"
                class="count-wrap"
              >
                {{ item.count }}
              </div>
              <gl-icon
                :height="14"
                name="flag"
                style="min-width: 14px"
                :width="14"
              />
            </template>
          </GlPriorityChip>
        </div>
      </gl-modal>
    </div>
  </div>
  <div v-else>
    --
  </div>
</template>

<script>
//Components
import GlPriorityChip from '@/components/gl-priority-chip.vue'
import GlIcon from '@/components/gl-icon'
import GlModal from '@/components/gl-modal'
import VueSkeletonLoader from 'skeleton-loader-vue'

export default {
  components: {
    GlIcon,
    GlPriorityChip,
    GlModal,
    VueSkeletonLoader,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    maxCount: {
      type: Number,
      default: 3,
    },
    flagsData: {
      type: Array,
      default: () => [],
    },
    aggregate: {
      type: Boolean,
      default: false,
    },
    showAll: {
      type: Boolean,
      default: false,
    },
    hideScore: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isModalOpen: false,
    }
  },
  computed: {
    flagsDataList() {
      const flagsData = [...this.sortedFlagsData]
      if (this.aggregate) {
        const grouped = Object.groupBy(flagsData, ({ priority }) => priority)
        const aggregatedArray = Object.keys(grouped)
          ?.sort((a, b) => b - a)
          ?.map(key =>
            grouped[key]?.reduce(
              (acc, obj) => {
                acc.count += obj?.count
                return acc
              },
              { count: 0, priority: key },
            ),
          )
        return aggregatedArray
      }

      if (flagsData.length > this.maxCount && !this.showAll) {
        flagsData.length = this.maxCount
      }
      return flagsData
    },
    sortedFlagsData() {
      return [...this.flagsData]?.sort((a, b) => b.priority - a.priority)
    },
  },
  methods: {
    clickOnChips() {
      if (!this.aggregate) return
      this.isModalOpen = true
    },
  },
}
</script>

<style>
.flags__score {
  font-weight: 500;
  padding: 0 6px;
  background: rgba(255, 255, 255, 0.25);
}
.show-more-flags {
  display: inline-flex;
  padding: 4px 8px;
  align-items: center;
  gap: 6px;
  flex-shrink: 0;
  border-radius: 17px;
  border: 1px solid var(--soft-blue);
  color: var(--soft-blue);
  cursor: pointer;
  line-height: 16px;
}

.count-wrap {
  padding: 0 6px;
  font-size: 10px;
  line-height: 17px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.25);
  min-width: 18px;
  text-align: center;
}

.count-wrap__empty {
  opacity: 0.7;
}
</style>
